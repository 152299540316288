/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import Tab from 'vux/src/components/tab/tab';
import TabItem from 'vux/src/components/tab/tab-item';
import ViewBox from 'vux/src/components/view-box';
import Scroll from '@/components/Scroll/Scroll';
export default {
  name: 'OutsideList',
  components: {
    ViewBox: ViewBox,
    Tab: Tab,
    TabItem: TabItem,
    Group: Group,
    Cell: Cell,
    Scroll: Scroll
  },
  data: function data() {
    return {
      infoList: [],
      pullDownRefresh: {
        threshold: 40,
        // 下拉多少开始刷新
        stop: 20 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      emptyShow: false,
      tabItem: this.$utils.Store.getItem('tabItem') || '0'
    };
  },
  computed: {},
  methods: {
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.infoList = [];
      this.pageIndex = 1;
      this.getInfoList();
    },
    onPullingUp: function onPullingUp() {
      this.pageIndex = this.pageIndex + 1;

      if (this.pageIndex > this.totalPage) {
        this.updateScroll();
        return;
      }

      if (this.isPullUp) return;
      this.isPullUp = true;
      this.getInfoList();
    },
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    getInfoList: function getInfoList() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.emptyShow = false;
      var params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        search: {
          userSerial: this.$utils.Store.getItem('userSerial'),
          type: this.tabItem
        }
      };
      this.$api.Attence.selectListPageByEntity(params).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);

          _this.updateScroll(false);
        } else {
          var _this$infoList;

          (_this$infoList = _this.infoList).push.apply(_this$infoList, _toConsumableArray(data.records));

          _this.pageIndex = data.pageIndex;
          _this.totalPage = data.totalPage;

          if (_this.infoList.length === 0) {
            _this.emptyShow = true;
          }

          _this.updateScroll(false);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    recordClick: function recordClick(value) {
      this.$router.push({
        name: 'OutsideForm',
        query: {
          id: value,
          tabItem: this.tabItem
        }
      });
    },
    onItemClick: function onItemClick(index) {
      this.tabItem = index;
      this.infoList = [];
      this.getInfoList();
    },
    submitData: function submitData() {}
  },
  created: function created() {
    this.getInfoList();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('tabItem');
  }
};